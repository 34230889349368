import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import caretRightIcon from '../assets/img/icons/bullet.png';
import Image from '../assets/img/services/1.jpg';

export default function Service1() {
    return (
        <div className='container'>
            <img src={Image} alt="Hero Background" className="img-fluid service-imgs mb-3" />

            <div className='service-details'>
                <h4>Handyman Services</h4>
                <p>
                    <b style={{ color: '#000' }}>Overview:</b> Our Handyman Services cover a wide array of minor repairs and maintenance tasks that keep your property in top shape. Whether it's fixing a leaky faucet, assembling furniture, or repairing a door hinge, our team is equipped to handle it all.
                </p>

                <h5>Features:</h5>
                <ul className='list-unstyled'>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Minor repairs (plumbing, electrical, carpentry)</li>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Furniture assembly</li>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Door and window repairs</li>
                    <li><img src={caretRightIcon} alt="caret right icon" /> General maintenance tasks</li>
                </ul>

                <h5>Benefits:</h5>
                <ul className='list-unstyled'>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Quick and reliable service</li>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Cost-effective solutions</li>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Flexible scheduling to suit your needs</li>
                </ul>
            </div>
        </div>
    );
}
