import React from 'react';
import HeroBg from '../assets/img/herobg.png';

export default function Hero() {
  return (
    <div className="hero-container">
      <img src={HeroBg} alt="Hero Background" className="hero-image img-fluid" />
      <div className="hero-content">
        {/* Add any content you want over the image here */}
        {/* <h1>Welcome to Our Site</h1>
        <p>Discover amazing content</p> */}
      </div>
    </div>
  );
}
