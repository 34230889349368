import React from 'react';
import ContactBg from '../assets/img/contact.png';

export default function ContactSection() {
  return (
    <div className='container'>
      <div className='heading-section mb-4'>
        <h2>Contact Us</h2>
        <p className='divider'></p>
        <p className='railway-font mb-5'>
          Please fill out the form below to get in touch with us. We look forward to hearing from you!
        </p>
      </div>

      <div className='contactuss mb-5'>
        <div className='row'>
          {/* Form Column */}
          <div className='col-md-7 col-12 mt-4'>
            <form>
              <div className='row mb-3'>
                <div className='col-md-6'>
                  <input
                    type='text'
                    className='form-control custom-input'
                    placeholder='Full Name'
                  />
                </div>
                <div className='col-md-6'>
                  <input
                    type='email'
                    className='form-control custom-input'
                    placeholder='Email Address'
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-md-6'>
                  <input
                    type='tel'
                    className='form-control custom-input'
                    placeholder='Phone Number'
                  />
                </div>
                <div className='col-md-6'>
                <div className='form-group mb-3'>
  <select
    id='servicesSelect'
    className='form-select custom-input'
    aria-label='Select a service'
  >
    <option value='' disabled selected>Select a service</option>
    <option value='handyman'>Handyman Services</option>
    <option value='interior-decorations'>Interior Decorations</option>
    <option value='carpentry-work'>Carpentry Work</option>
    <option value='air-conditioning'>Air-Conditioning</option>
    <option value='electrical-plumbing'>Electrical & Plumbing</option>
    <option value='painting'>Interior & Exterior Painting</option>
    <option value='flooring-tile'>Flooring & Tile Works</option>
    <option value='networking-cctv'>Networking & CCTV Installation</option>
  </select>
</div>

                </div>
              </div>
              <div className='mb-3 mt-5'>
                <textarea
                  className='form-control custom-textarea'
                  rows='10'
                  placeholder='Questions or Comments'
                ></textarea>
              </div>
              <div className='contact-submit d-flex justify-content-end'>
  <button type='submit' className='btn btn-primary'>Submit</button>
</div>

            </form>
          </div>

          {/* Image Column */}
          <div className='col-md-5 col-12'>
            <div className=' hide-mobile'>
            <img src={ContactBg} alt='Contact Background' className='img-fluid' />

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
