import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import caretRightIcon from '../assets/img/icons/bullet.png';
import Image from '../assets/img/services/6.jpg';

export default function Service6() {
    return (
        <div className='container'>
            <img src={Image} alt="Hero Background" className="img-fluid service-imgs mb-3" />

            <div className='service-details'>
                <h4>Interior & Exterior Painting</h4>
                <p>
                    <b style={{ color: '#000' }}>Overview:</b> Refresh the look of your property with our Interior & Exterior Painting Services. We provide high-quality painting solutions that not only beautify your space but also protect it from the elements.

                </p>

                <h5>Features:</h5>
                <ul className='list-unstyled'>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Interior wall and ceiling painting</li>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Exterior façade and trim painting</li>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Surface preparation and priming</li>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Use of high-quality, durable paints</li>
                </ul>

                <h5>Benefits:</h5>
                <ul className='list-unstyled'>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Enhanced aesthetic appeal of your property</li>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Protection against weathering and damage</li>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Professional finishes that last longer</li>
                </ul>
            </div>
        </div>
    );
}
