import React, { useLayoutEffect } from 'react';
import Hero from '../components/Hero'
import ContactRow from '../components/ContactRow'
import HomeIntro from '../components/HomeIntro'
import ServicesBox from '../components/ServicesBox'
import HomeTesto from '../components/HomeTesto'
import ContactRowHeadings from '../components/ContactRowHeadings'

export default function Home() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Hero/>
      <HomeIntro/>
      <ServicesBox/>
      <HomeTesto/>
      <ContactRowHeadings/>
      <ContactRow/>
    </div>
  )
}
