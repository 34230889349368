import React from 'react'

import Image1 from '../assets/img/1.png';
import Image2 from '../assets/img/2.png';
import Image3 from '../assets/img/3.png';


export default function GalleryDesign() {
  return (
   
    <div className='container'>
    <div className='heading-section'>
<h2>Gallery</h2>
<p className='deivider'></p>
<p>Explore our portfolio of successful projects, showcasing the quality and craftsmanship that define Aura Energy Technical Services LLC. From elegant interior designs to expertly installed systems, our gallery highlights the range of services we provide.</p>

</div>


<div className='images-gallery mb-5'>
<img src={Image1} alt="Hero Background" className=" img-fluid" />
<img src={Image2} alt="Hero Background" className=" img-fluid" />
<img src={Image3} alt="Hero Background" className=" img-fluid" />
</div>
</div>

  )
}
