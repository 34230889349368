import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import caretRightIcon from '../assets/img/icons/bullet.png';
import Image from '../assets/img/services/2.jpg';

export default function Service2() {
    return (
        <div className='container'>
            <img src={Image} alt="Hero Background" className="img-fluid service-imgs mb-3" />

            <div className='service-details'>
                <h4>Interior Decorations</h4>
                <p>
                    <b style={{ color: '#000' }}>Overview:</b> Transform your space with our expert Interior Decoration services. We work closely with you to understand your vision and bring it to life, ensuring that every detail reflects your unique style and taste.
                </p>

                <h5>Features:</h5>
                <ul className='list-unstyled'>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Custom design consultations</li>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Selection of materials, colors, and furnishings</li>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Space planning and layout design</li>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Complete project management from start to finish</li>
                </ul>

                <h5>Benefits:</h5>
                <ul className='list-unstyled'>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Tailored solutions to match your aesthetic</li>
                    <li><img src={caretRightIcon} alt="caret right icon" /> High-quality materials and craftsmanship</li>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Enhance the value and appeal of your property</li>
                </ul>
            </div>
        </div>
    );
}
