import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import caretRightIcon from '../assets/img/icons/bullet.png';
import Image from '../assets/img/services/7.jpg';

export default function Service7() {
    return (
        <div className='container'>
            <img src={Image} alt="Hero Background" className="img-fluid service-imgs mb-3" />

            <div className='service-details'>
                <h4>Flooring & Tile Works</h4>
                <p>
                    <b style={{ color: '#000' }}>Overview:</b> Our Flooring & Tile Services offer stylish and durable solutions for any space. Whether you're looking to install new flooring or update your tiles, we provide a wide range of options to match your design preferences.


                </p>

                <h5>Features:</h5>
                <ul className='list-unstyled'>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Installation of various flooring types (hardwood, laminate, vinyl, etc.)</li>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Tile installation for kitchens, bathrooms, and more</li>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Floor repairs and refinishing</li>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Custom tile designs and patterns</li>
                </ul>

                <h5>Benefits:</h5>
                <ul className='list-unstyled'>
                    <li><img src={caretRightIcon} alt="caret right icon" /> High-quality materials that stand the test of time</li>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Customizable options to suit your style</li>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Improved property value and aesthetic appeal</li>
                </ul>
            </div>
        </div>
    );
}
