import React from 'react'

export default function ContactRowHeadings() {
  return (
    <div className='container'>
        <div className='heading-section'>
    <h2>Contact Us</h2>
    <p className='deivider'></p>
    <p>Looking for reliable and professional technical services? <b>Contact us today</b> to discuss your requirements and get started with a service that meets your needs.</p>

</div>
    </div>
  )
}
