import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import caretRightIcon from '../assets/img/icons/bullet.png';
import Image from '../assets/img/services/8.jpg';

export default function Service8() {
    return (
        <div className='container'>
            <img src={Image} alt="Hero Background" className="img-fluid service-imgs mb-3" />

            <div className='service-details'>
                <h4>Networking & CCTV Installation</h4>
                <p>
                    <b style={{ color: '#000' }}>Overview:</b> Stay connected and secure with our Networking & CCTV Installation Services. We offer advanced solutions for both residential and commercial properties, ensuring that your network is fast and reliable, and your property is well-protected.


                </p>

                <h5>Features:</h5>
                <ul className='list-unstyled'>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Installation of high-speed networking systems</li>
                    <li><img src={caretRightIcon} alt="caret right icon" /> CCTV camera setup and monitoring</li>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Security system integration</li>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Ongoing maintenance and support</li>
                </ul>

                <h5>Benefits:</h5>
                <ul className='list-unstyled'>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Improved connectivity and communication</li>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Enhanced security and peace of mind</li>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Professional installation with minimal disruption
</li>
                </ul>
            </div>
        </div>
    );
}
