import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import caretRightIcon from '../assets/img/icons/bullet.png';
import Image from '../assets/img/services/3.jpg';

export default function Service3() {
    return (
        <div className='container'>
            <img src={Image} alt="Hero Background" className="img-fluid service-imgs mb-3" />

            <div className='service-details'>
                <h4>Carpentry Work</h4>
                <p>
                    <b style={{ color: '#000' }}>Overview:</b> Our Carpentry Services are designed to enhance both the functionality and aesthetics of your property. From custom cabinetry to intricate woodwork, our skilled carpenters bring your ideas to life with precision and creativity.
                </p>

                <h5>Features:</h5>
                <ul className='list-unstyled'>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Custom cabinetry and furniture design</li>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Door and window installations</li>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Shelving and storage solutions</li>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Decorative woodwork and trim</li>
                </ul>

                <h5>Benefits:</h5>
                <ul className='list-unstyled'>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Durable and long-lasting craftsmanship</li>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Custom designs that fit your space perfectly</li>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Increased property value through quality enhancements</li>
                </ul>
            </div>
        </div>
    );
}
