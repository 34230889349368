import React from 'react'

export default function AboutContant() {
  return (
    <div className='about-coontant'>
        <div className='container'>
        <div className='heading-section'>
        <h2 className='railway-font'>WHO WE ARE </h2>
        <p  className='deivider'></p>
      </div>

      <h5 className='mb-4' style={{fontWeight:'600'}}>Welcome to Aura Energy Technical Services LLC!
</h5>

<p classNmae="mb-4">Renovation across Dubai. Our mission is to deliver high-quality interior work and continuously improve our services to meet the highest standards.</p>

<p className="mb-4">At Aura Energy, we believe in transforming the traditional maintenance process into a seamless experience built on trust and excellence. Our founding partner aimed to create a company that not only meets but exceeds client expectations. This vision has led us to expand our services to include complete renovation and project management, ensuring every aspect of your project is handled with care and expertise.</p>

<p className="mb-4">We take pride in being one of the most reliable service providers in the industry. Our goal is to make property maintenance as easy and affordable as possible for our clients. Whether you're a homeowner or a commercial property manager, you can count on us to meet your maintenance needs promptly and effectively, anytime.</p>

<p className="mb-4">Our core values—commitment, customer satisfaction, and reliability—guide everything we do. We are dedicated to delivering exceptional results and ensuring that every client is completely satisfied with our work.</p>

<p>Thank you for choosing Aura Energy Technical Services LLC. We look forward to serving you and showcasing the quality and dedication that define our work.</p>

<p></p>


<div className='heading-section'>
        <h2>Vision: </h2>
        <p  className='deivider'></p>
      </div>


      <p className="mb-4">To be the most trusted partner in property maintenance and renovation, setting new standards of excellence and innovation in the industry.
</p>



      <div className='heading-section'>
        <h2>Mission: </h2>
        <p  className='deivider'></p>
      </div>

      <p className="mb-4"> Our mission is to provide top-quality technical services that exceed customer expectations, delivered with integrity, efficiency, and a commitment to long-term client satisfaction. We aim to make every project seamless, ensuring our clients receive the best value and experience.
</p>


      <p className='mb-5'></p>
        </div>
    </div>
  )
}
