import React from 'react';
import HeroBg from '../assets/img/about-img.png';



export default function HomeIntro() {
  return (
    <div className="container my-5">
      <div className="row">
        <div className="col-md-5 d-flex align-items-center">
          <div className='intero-section'>
            <h1>Introduction:</h1>
    <p className='deivider text-center'></p>

            <p  className='mb-4'>
            Welcome to Aura Energy Technical Services LLC, your trusted partner in delivering top-quality technical services across Dubai. We offer a comprehensive range of maintenance and repair solutions for both residential and commercial properties. Our team of skilled employees and engineers are dedicated to providing the highest standard of service, ensuring that every project is completed with precision and care. Backed by a professionally managed support team, we are always ready to address your needs and queries promptly, ensuring a seamless experience from start to finish.
            </p>
          </div>
        </div>
        <div className="col-md-7">
        <img src={HeroBg} alt="Hero Background" className="intro-img img-fluid" />
        </div>
      </div>
    </div>
  );
}
