import React, { useLayoutEffect } from 'react';
import AboutContant from '../components/AboutContant'
import ContactRow from '../components/ContactRow'

export default function WhoWeAre() {
  
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <AboutContant/>
      <ContactRow/>
    </div>
  )
}
