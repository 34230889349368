import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logoImage from '../assets/img/logo/logo.png';

export default function Nevbar() {

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isShopSubMenuOpen, setIsShopSubMenuOpen] = useState(false);
  const [isMenSubMenuOpen, setIsMenSubMenuOpen] = useState(false);
  const [isWomenSubMenuOpen, setIsWomenSubMenuOpen] = useState(false);

  const openMenu = () => {
    console.log('Opening menu');
    setIsMenuOpen(true);
  };

  const closeMenu = () => {
    console.log('Closing menu');
    setIsMenuOpen(false);
  };

  const toggleShopSubMenu = () => {
    setIsShopSubMenuOpen(!isShopSubMenuOpen);
  };

  const toggleMenSubMenu = () => {
    setIsMenSubMenuOpen(!isMenSubMenuOpen);
  };

  const toggleWomenSubMenu = () => {
    setIsWomenSubMenuOpen(!isWomenSubMenuOpen);
  };
  return (

    <div>
    <div className="main__header header__sticky">
      <div className="container">
        <div className="main__header--inner position__relative d-flex justify-content-between align-items-center">
          <div className="main__logo">
            <h1 className="main__logo--title">
              <Link to='/' className="main__logo--link">
                <img className="main__logo--img" src={logoImage} alt="logo-img" />
              </Link>
            </h1>
          </div>

          <div className={`offcanvas__header--menu__open ${isMenuOpen ? 'active' : ''}`}>
            <Link className="offcanvas__header--menu__open--btn" onClick={isMenuOpen ? closeMenu : openMenu}>
              <svg xmlns="http://www.w3.org/2000/svg" className="ionicon offcanvas__header--menu__open--svg" viewBox="0 0 512 512">
                <path
                  fill="currentColor"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeMiterlimit={10}
                  strokeWidth={32}
                  d="M80 160h352M80 256h352M80 352h352"
                />
              </svg>
              <span className="visually-hidden">Menu Open</span>
            </Link>
          </div>

          <div className="header__search--widget header__sticky--none d-none d-lg-block">
            <div className="row">
              <div className="col">
                <nav className="header__menu--navigation">
                  <ul className="d-flex">
                    <li className="header__menu--items style2">
                      <Link to='/' className="header__menu--link">HOME</Link>
                    </li>
                    <li className="header__menu--items style2">
                      <Link className="header__menu--link" to="/who-we-are">WHO WE ARE</Link>
                    </li>
                    <li className="header__menu--items style2">
                      <Link className="header__menu--link" to="/what-we-do">WHAT WE DO</Link>
                    </li>
                    <li className="header__menu--items style2">
                      <Link className="header__menu--link" to="/gallery">GALLERY</Link>
                    </li>
                    <li className="header__menu--items style2">
                      <Link className="header__menu--link" to="/contact-us">CONTACT US</Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>

          <div className="header__menu d-none header__sticky--block d-lg-block">
            <nav className="header__menu--navigation">
              <ul className="d-flex">
                <li className="header__menu--items style2">
                  <Link to='/' className="header__menu--link">HOME</Link>
                </li>
                <li className="header__menu--items style2">
                  <Link className="header__menu--link" to="/who-we-are">WHO WE ARE</Link>
                </li>
                <li className="header__menu--items style2">
                  <Link className="header__menu--link" to="/what-we-do">WHAT WE DO</Link>
                </li>
                <li className="header__menu--items style2">
                  <Link className="header__menu--link" to="/gallery">GALLERY</Link>
                </li>
                <li className="header__menu--items style2">
                  <Link className="header__menu--link" to="/contact-us">CONTACT US</Link>
                </li>
              </ul>
            </nav>
          </div>

        </div>
      </div>
    </div>

    {/* Start Offcanvas header menu */}
    {isMenuOpen && (
      <div className="offcanvas__header open">
        <div className="offcanvas__inner">
          <div className="offcanvas__logo">
            <Link className="offcanvas__logo_link" to="/">
              <img src={logoImage} alt="Logo" width={158} height={36} />
            </Link>
            <button className="offcanvas__close--btn" onClick={closeMenu}>close</button>
          </div>
          <nav className="offcanvas__menu">
            <ul className="offcanvas__menu_ul">
              <li className="offcanvas__menu_li">
                <Link className="offcanvas__menu_item" to="/">HOME</Link>
              </li>
              <li className="offcanvas__menu_li">
                <Link className="offcanvas__menu_item" to="/who-we-are">WHO WE ARE</Link>
              </li>
              <li className="offcanvas__menu_li">
                <Link className="offcanvas__menu_item" to="/what-we-do">WHAT WE DO</Link>
              </li>
              <li className="offcanvas__menu_li">
                <Link className="offcanvas__menu_item" to="/gallery">GALLERY</Link>
              </li>
              <li className="offcanvas__menu_li">
                <Link className="offcanvas__menu_item" to="/contact-us">CONTACT US</Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    )}
  </div>
)
}
