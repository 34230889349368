import React from 'react'

export default function WhatweDoHeadings() {
  return (
    <div className='container'>
    <div className='heading-section'>
<h2 className='railway-font'>WHAT WE DO</h2>
<p className='deivider'></p>
<p>Aura Energy Technical Services LLC is committed to providing a comprehensive range of high-quality services designed to meet the diverse needs of both residential and commercial clients. Our team of skilled professionals ensures that every project is handled with the utmost care, expertise, and attention to detail.</p>

</div>
</div>
  )
}
