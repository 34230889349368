import React from 'react'
import { Link } from 'react-router-dom';
import logoImage from '../assets/img/logo/logo-white.png';
import TwitterIcon from '../assets/img/icons/x.png';


export default function
  () {
  return (
    <div>
      {/* Bootstrap 5 Footer */}
      <footer className="text-white footer">
        <div className="container py-5 ">
          <div className="row">
            {/* Column 1: Logo and Social Media */}
            <div className="col-md-4">
              <div className='footer-logo'>
                <img src={logoImage} alt="Brand Logo" className="mb-3" />
              </div>
              <p style={{ marginRight: '40px', lineHeight: '20px' }}>Welcome to Aura Energy Technical Services LLC, your trusted partner in delivering top-quality technical services across Dubai. We offer a comprehensive range of maintenance and repair solutions for both residential and commercial properties</p>
              <div className="d-flex">
                <a href="https://facebook.com" className="me-2 text-white rounded-circle border border-white d-flex align-items-center justify-content-center" style={{ width: 40, height: 40 }}>
                  <i className="fab fa-facebook-f" />
                </a>
                <a href="https://twitter.com" className="me-2 text-white rounded-circle border border-white d-flex align-items-center justify-content-center" style={{ width: 40, height: 40 }}>
                <img src={TwitterIcon} alt="Twitter" className='img-fluid' style={{ maxWidth: '16px', height: 'auto' }} />
                </a>

                <a href="https://instagram.com" className="me-2 text-white rounded-circle border border-white d-flex align-items-center justify-content-center" style={{ width: 40, height: 40 }}>
                  <i className="fab fa-instagram" />
                </a>
                <a href="https://linkedin.com" className="text-white rounded-circle border border-white d-flex align-items-center justify-content-center" style={{ width: 40, height: 40 }}>
                  <i className="fab fa-linkedin-in" />
                </a>
              </div>
            </div>
            {/* Column 2: Useful Links */}
            <div className="col-md-2">
              <h3 className="mb-3">Useful Links</h3>
              <ul className="footer-links">
                <li><Link to={'/'} className="text-white text-decoration-none"><i className="fas fa-chevron-right" /> Home</Link></li>
                <li><Link to={'/who-we-are'} className="text-white text-decoration-none"><i className="fas fa-chevron-right" /> About Us</Link></li>
                <li><Link to={'/gallery'} className="text-white text-decoration-none"><i className="fas fa-chevron-right" /> Gallery</Link></li>
                <li><Link to={'/contact-us'} className="text-white text-decoration-none"><i className="fas fa-chevron-right" /> Contact Us</Link></li>
              </ul>
            </div>
            {/* Column 3: Quick Links */}
            <div className="col-md-3">
              <h3 className="mb-3">Services</h3>
              <ul className="footer-links">
              <ul className="footer-links">
                <li><Link to={'/what-we-do#handyman'} className="text-white text-decoration-none"><i className="fas fa-chevron-right" /> Handyman Services</Link></li>
                <li><Link to={'/what-we-do#interior-decorations'} className="text-white text-decoration-none"><i className="fas fa-chevron-right" /> Interior Decorations</Link></li>
                <li><Link to={'/what-we-do#carpentry-work'} className="text-white text-decoration-none"><i className="fas fa-chevron-right" /> Carpentry Work</Link></li>
                <li><Link to={'/what-we-do#air-conditioning'} className="text-white text-decoration-none"><i className="fas fa-chevron-right" /> Air-Conditioning</Link></li>
                <li><Link to={'/what-we-do#electrical-plumbing'} className="text-white text-decoration-none"><i className="fas fa-chevron-right" /> Electrical & Plumbing</Link></li>
                <li><Link to={'/what-we-do#painting'} className="text-white text-decoration-none"><i className="fas fa-chevron-right" /> Interior & Exterior Painting</Link></li>
                <li><Link to={'/what-we-do#flooring-tile'} className="text-white text-decoration-none"><i className="fas fa-chevron-right" /> Flooring & Tile Works</Link></li>
                <li><Link to={'/what-we-do#networking-cctv'} className="text-white text-decoration-none"><i className="fas fa-chevron-right" /> Networking & CCTV Installation</Link></li>
              </ul>
              </ul>
            </div>

            <div className="col-md-3">
              <div className='footer-details'>
                <h3 className="mb-3">Quick Contact</h3>
                <div className="contact-item d-flex align-items-start mb-2">
                  <i className="fas fa-map-marker-alt me-2 mt-3"></i>
                  <p className='mb-0'> &nbsp;#03, Deluxe Star Business Centre, Al Khabeesi Building, Deira.</p>
                </div>
                <div className="contact-item d-flex align-items-start mb-2">
                  <i className="fas fa-phone me-2 mt-3  flip-icon" style={{ transform: "scaleX(-1)" }}></i>
                  <p className='mb-0'> &nbsp;+971 4 3986756 | +971 55 9243898</p>
                </div>
                <div className="contact-item d-flex align-items-start mb-2">
                  <i className="fas fa-envelope me-2 mt-3"></i>
                  <p className='mb-0'> &nbsp;info@auraenergytechnical.com</p>
                </div>
              </div>
            </div>



          </div>
        </div>
      </footer>

      <div className="col-12 text-center  colpywright-line">
        <p> © 2024  Wintech . All Rights Reserved. Design & Develop by : Prodigy Marketing Agency</p>
      </div>



    </div>
  )
}
