import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import caretRightIcon from '../assets/img/icons/bullet.png';
import Image from '../assets/img/services/4.jpg';

export default function Service4() {
    return (
        <div className='container'>
            <img src={Image} alt="Hero Background" className="img-fluid service-imgs mb-3" />

            <div className='service-details'>
                <h4>Air-Conditioning</h4>
                <p>
                    <b style={{ color: '#000' }}>Overview:</b>  Keep your home or office comfortable year-round with our comprehensive Air-Conditioning Services. We offer installation, maintenance, and repair services for all types of air-conditioning systems, ensuring optimal performance and energy efficiency.

                </p>

                <h5>Features:</h5>
                <ul className='list-unstyled'>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Installation of new air-conditioning units</li>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Regular maintenance and cleaning</li>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Repair and troubleshooting services</li>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Energy-efficient solutions</li>
                </ul>

                <h5>Benefits:</h5>
                <ul className='list-unstyled'>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Enhanced indoor comfort</li>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Prolonged lifespan of your AC units</li>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Reduced energy costs through efficient operation</li>
                </ul>
            </div>
        </div>
    );
}
