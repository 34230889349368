import React from 'react';

export default function ContactRow() {
  return (
    <div className="container my-5">


      <div className="row">
        <div className="col-md-6 col-lg-3 my-auto col-6 mb-4">
          <div className="  contact-card text-center">
            <h3 className="mb-3">LOCATION</h3>
            <p className='mb-2'>#03 , Deluxe Star Business centre,
Al Khabeesi Building, Deira.
Dubai , UAE
</p>
<p><a style={{textDecoration:'underline'}}>Map Directions</a></p>
          </div>
        </div>
        <div className="col-md-6 col-lg-3 my-auto col-6 mb-4">
          <div className=" contact-card text-center my-auto">
            <h3 className="mb-3">CONTACT</h3>
            <p className='mb-1'><b>Land Line:</b> +971 4 3986756 </p>
            <p><b>Call / WhatsApp</b> +971 55 9243898 </p>
          </div>
        </div>
        <div className="col-md-6 col-lg-3 my-auto col-6 mb-4">
          <div className=" contact-card text-center">
            <h3 className="mb-3" >EMAIL</h3>
            <p style={{wordBreak:'break-all'}}>info@auraenergytechnical.com</p>
          </div>
        </div>
        <div className="col-md-6 col-lg-3 my-auto col-6 mb-4">
          <div className=" contact-card text-center">
            <h3 className="mb-3">Business
Hours</h3>
            <p>24/7</p>
          </div>
        </div>
      </div>
    </div>
  );
}
