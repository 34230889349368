import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import caretRightIcon from '../assets/img/icons/bullet.png';
import Image from '../assets/img/services/5.jpg';

export default function Service5() {
    return (
        <div className='container'>
            <img src={Image} alt="Hero Background" className="img-fluid service-imgs mb-3" />

            <div className='service-details'>
                <h4>Electrical & Plumbing</h4>
                <p>
                    <b style={{ color: '#000' }}>Overview:</b>  Our Electrical & Plumbing Services provide safe, efficient, and reliable solutions for all your property’s needs. From wiring and installations to leak repairs and pipe replacements, we ensure that your systems are functioning smoothly.

                </p>

                <h5>Features:</h5>
                <ul className='list-unstyled'>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Electrical installations and repairs</li>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Plumbing installations and leak repairs</li>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Regular system inspections and maintenance</li>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Compliance with safety standards and regulations</li>
                </ul>

                <h5>Benefits:</h5>
                <ul className='list-unstyled'>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Safe and secure electrical and plumbing systems</li>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Prevention of costly damage through timely repairs</li>
                    <li><img src={caretRightIcon} alt="caret right icon" /> Peace of mind knowing your property is in expert hands</li>
                </ul>
            </div>
        </div>
    );
}
