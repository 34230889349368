import React from 'react';
import { Link } from 'react-router-dom';
import Image1 from '../assets/img/services/1.jpg';
import Image2 from '../assets/img/services/2.jpg';
import Image3 from '../assets/img/services/3.jpg';
import Image4 from '../assets/img/services/4.jpg';
import Image5 from '../assets/img/services/5.jpg';
import Image6 from '../assets/img/services/6.jpg';
import Image7 from '../assets/img/services/7.jpg';
import Image8 from '../assets/img/services/8.jpg';

export default function ServicesBox() {
  return (
    <div className='container'>
      <div className='heading-section'>
        <h2>Featured Services</h2>
        <p  className='deivider'></p>
      </div>

      <div className='servieces-section'
>

<div className='row'>
        <div className='col-md-6 col-12 mb-4'>
          <div className='service-box'>
            <img src={Image1} alt="Interior Decorations" className="" />
            <div className='service-details'>
              <h6>Handyman Services: </h6>
              <p>Quick and reliable solutions for everyday repairs and maintenance.</p>
              <p className='proice-details'><Link to={'/contact-us'}>Price Available on Request</Link></p>
              <div className='border-line'></div>
              <div className='box-footer'>
                <div className='row'>
                  <div className='col-6'>
                  <p className='mb-0'><Link to={'/what-we-do#handyman'}>View Details</Link></p>
                  </div>
                  <div className='col-6 text-end'>
                    <p className='mb-0' style={{ cursor: 'pointer', display: 'inline' }}>
                      <i className="fas fa-share"></i> Share
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='col-md-6 col-12 mb-4'>
          <div className='service-box'>
            <img src={Image2} alt="Exterior Design" className="" />
            <div className='service-details'>
              <h6>Interior Decorations:</h6>
              <p>Transform your space with our expert design and decoration services.</p>
              <p className='proice-details'><Link to={'/contact-us'}>Price Available on Request</Link></p>
              <div className='border-line'></div>
              <div className='box-footer'>
                <div className='row'>
                  <div className='col-6'>
                  <p className='mb-0'><Link to={'/what-we-do#interior-decorations'}>View Details</Link></p>
                  </div>
                  <div className='col-6 text-end'>
                    <p className='mb-0' style={{ cursor: 'pointer', display: 'inline' }}>
                      <i className="fas fa-share"></i> Share
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className='row'>
        <div className='col-md-6 col-12 mb-4'>
          <div className='service-box'>
            <img src={Image3} alt="Landscaping" className="" />
            <div className='service-details'>
              <h6>Carpentry Work:</h6>
              <p>Custom woodworking and furniture crafting to enhance your interiors.</p>
              <p className='proice-details'><Link to={'/contact-us'}>Price Available on Request</Link></p>
              <div className='border-line'></div>
              <div className='box-footer'>
                <div className='row'>
                  <div className='col-6'>
                  <p className='mb-0'><Link to={'/what-we-do#carpentry-work'}>View Details</Link></p>
                  </div>
                  <div className='col-6 text-end'>
                    <p className='mb-0' style={{ cursor: 'pointer', display: 'inline' }}>
                      <i className="fas fa-share"></i> Share
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='col-md-6 col-12 mb-4'>
          <div className='service-box'>
            <img src={Image4} alt="Custom Furniture" className="" />
            <div className='service-details'>
              <h6>Air-Conditioning:</h6>
              <p>Installation, maintenance, and repair of all air-conditioning systems.</p>
              <p className='proice-details'><Link to={'/contact-us'}>Price Available on Request</Link></p>
              <div className='border-line'></div>
              <div className='box-footer'>
                <div className='row'>
                  <div className='col-6'>
                  <p className='mb-0'><Link to={'/what-we-do#air-conditioning'}>View Details</Link></p>
                  </div>
                  <div className='col-6 text-end'>
                    <p className='mb-0' style={{ cursor: 'pointer', display: 'inline' }}>
                      <i className="fas fa-share"></i> Share
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className='row'>
        <div className='col-md-6 col-12 mb-4'>
          <div className='service-box'>
            <img src={Image5} alt="Landscaping" className="" />
            <div className='service-details'>
              <h6>Electrical & Plumbing: </h6>
              <p>Safe and efficient electrical and plumbing services for your home or office.</p>
              <p className='proice-details'><Link to={'/contact-us'}>Price Available on Request</Link></p>
              <div className='border-line'></div>
              <div className='box-footer'>
                <div className='row'>
                  <div className='col-6'>
                  <p className='mb-0'><Link to={'/what-we-do#electrical-plumbing'}>View Details</Link></p>
                  </div>
                  <div className='col-6 text-end'>
                    <p className='mb-0' style={{ cursor: 'pointer', display: 'inline' }}>
                      <i className="fas fa-share"></i> Share
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='col-md-6 col-12 mb-4'>
          <div className='service-box'>
            <img src={Image6} alt="Custom Furniture" className="" />
            <div className='service-details'>
              <h6>Interior & Exterior Painting:</h6>
              <p>High-quality painting services that breathe new life into your space.</p>
              <p className='proice-details'><Link to={'/contact-us'}>Price Available on Request</Link></p>
              <div className='border-line'></div>
              <div className='box-footer'>
                <div className='row'>
                  <div className='col-6'>
                  <p className='mb-0'><Link to={'/what-we-do#painting'}>View Details</Link></p>
                  </div>
                  <div className='col-6 text-end'>
                    <p className='mb-0' style={{ cursor: 'pointer', display: 'inline' }}>
                      <i className="fas fa-share"></i> Share
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div className='row'>
        <div className='col-md-6 col-12 mb-4'>
          <div className='service-box'>
            <img src={Image7} alt="Landscaping" className="" />
            <div className='service-details'>
              <h6>Flooring & Tile Works: </h6>
              <p>Durable and stylish flooring and tile installation to suit any decor.</p>
              <p className='proice-details'><Link to={'/contact-us'}>Price Available on Request</Link></p>
              <div className='border-line'></div>
              <div className='box-footer'>
                <div className='row'>
                  <div className='col-6'>
                  <p className='mb-0'><Link to={'/what-we-do#flooring-tile'}>View Details</Link></p>
                  </div>
                  <div className='col-6 text-end'>
                    <p className='mb-0' style={{ cursor: 'pointer', display: 'inline' }}>
                      <i className="fas fa-share"></i> Share
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='col-md-6 col-12 mb-4'>
          <div className='service-box'>
            <img src={Image8} alt="Custom Furniture" className="" />
            <div className='service-details'>
              <h6>Networking & CCTV Installation:</h6>
              <p> Advanced networking solutions and security systems installation.</p>
              <p className='proice-details'><Link to={'/contact-us'}>Price Available on Request</Link></p>
              <div className='border-line'></div>
              <div className='box-footer'>
                <div className='row'>
                  <div className='col-6'>
                    <p className='mb-0'><Link to={'/what-we-do#networking-cctv'}>View Details</Link></p>
                  </div>
                  <div className='col-6 text-end'>
                    <p className='mb-0' style={{ cursor: 'pointer', display: 'inline' }}>
                      <i className="fas fa-share"></i> Share
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</div>


      {/* Additional row for more services if needed */}

    </div>
  );
}
