
import { BrowserRouter as Router, Route, Switch, Routes } from 'react-router-dom';
import Home from './pages/Home';
import WhoWeAre from './pages/WhoWeAre';
import WhatWeDo from './pages/WhatWeDo';
import Gallery from './pages/Gallery';
import ContactUs from './pages/ContactUs';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

import './assets/css/style.css';


function App() {
  return (
   
    <div className="App">
      <>

      <Router>
        <Navbar />
        <Routes>
          <Route  path="/" element={<Home/>} />
          <Route path="/who-we-are" element={<WhoWeAre/>} />
          <Route path="/what-we-do" element={<WhatWeDo/>} />
          <Route path="/gallery" element={<Gallery/>} />
          <Route path="/contact-us" element={<ContactUs/>} />
        </Routes>
        <Footer />
      </Router>

      </>
    </div>
  );
}

export default App;
