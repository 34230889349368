import React from 'react';
import Slider from 'react-slick';
import TestimonialImage1 from '../assets/img/testo.png';
import TestimonialImage2 from '../assets/img/testo.png';
import TestimonialImage3 from '../assets/img/testo.png';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome CSS

// Custom arrow components
const CustomPrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={`${className} custom-arrow`} onClick={onClick}>
<i class="fa-solid fa-circle-chevron-left"></i>    </div>
  );
};

const CustomNextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={`${className} custom-arrow`} onClick={onClick}>
<i class="fa-solid fa-circle-chevron-right"></i>    </div>
  );
};

export default function HomeTesto() {
  const settings = {
    dots: false, // Remove dots if not needed
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true, // Ensure arrows are enabled
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <div className="home-testo container">

<div className='heading-section'>
        <h2>What our Client say</h2>
        <p  className='deivider'></p>
      </div>

      <Slider {...settings}>
        <div>
          <div className="row g-0 align-items-center">
            <div className="col-md-6  my-auto">
                <div className='test-contant'>
              <h4>Sarah L.</h4>
              <p className='divider'></p>
              <p>Aura Energy transformed our office space with their exceptional interior decoration services. Highly recommend!</p>
            </div>
            </div>
            <div className="col-md-6">
              <img src={TestimonialImage1} alt="Testimonial 1" className="img-fluid" />
            </div>
          </div>
        </div>

        <div>
          <div className="row align-items-center">
            <div className="col-md-6  my-auto">
            <div className='test-contant'>
              <h4>Mark T.</h4>
              <p className='divider'></p>
              <p>Quick, efficient, and professional – their handyman services are top-notch.</p>
            </div>
            </div>
            <div className="col-md-6">
              <img src={TestimonialImage2} alt="Testimonial 2" className="img-fluid" />
            </div>
          </div>
        </div>

        <div>
          <div className="row align-items-center">
            <div className="col-md-6 my-auto">
            <div className='test-contant'>
              <h4>Emma R.</h4>
              <p className='divider'></p>
              <p>Their team handled our electrical and plumbing work flawlessly. We're very satisfied.</p>
            </div>
            </div>
            <div className="col-md-6">
              <img src={TestimonialImage3} alt="Testimonial 3" className="img-fluid" />
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
}
