import React, { useLayoutEffect } from 'react';
import ContactSection from '../components/ContactSection'
import ContactRow from '../components/ContactRow'


export default function ContactUs() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <ContactSection/>

      <div className='contacxt-spacing'>
      <ContactRow/>

        </div>
     
    </div>
  )
}
