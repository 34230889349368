import React, { useEffect, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import WhatweDoHeadings from '../components/WhatweDoHeadings';
import Service1 from '../components/Service1';
import Service2 from '../components/Service2';
import Service3 from '../components/Service3';
import Service4 from '../components/Service4';
import Service5 from '../components/Service5';
import Service6 from '../components/Service6';
import Service7 from '../components/Service7';
import Service8 from '../components/Service8';

export default function WhatWeDo() {
  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on initial load
  }, []);

  useEffect(() => {
    // Initialize Bootstrap tabs if needed
    const tabElements = document.querySelectorAll('button[data-bs-toggle="pill"]');
    const tabInstances = Array.from(tabElements).map(tabElement => new window.bootstrap.Tab(tabElement));

    const handleHashChange = () => {
      const hash = window.location.hash || '#handyman'; // Default to #handyman
      const targetTab = document.querySelector(`button[data-bs-target="${hash}"]`);
      if (targetTab) {
        const tab = new window.bootstrap.Tab(targetTab);
        tab.show();

        // Scroll to the specific section or top of the page
        const targetSection = document.querySelector(hash);
        if (targetSection) {
          // targetSection.scrollIntoView({ behavior: 'smooth' });
        } else {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }
    };

    handleHashChange();

    window.addEventListener('hashchange', handleHashChange);

    const handleTabShown = (event) => {
      const hash = event.target.getAttribute('data-bs-target');
      window.history.pushState(null, null, hash);
    };

    // Add event listeners to update URL hash when tab changes
    tabElements.forEach(button => {
      button.addEventListener('shown.bs.tab', handleTabShown);
    });

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
      tabElements.forEach(button => {
        button.removeEventListener('shown.bs.tab', handleTabShown);
      });
    };
  }, [location.hash]); // Re-run useEffect when location.hash changes

  return (
    <div>
      <WhatweDoHeadings/>
      <div className='container my-5'>
        <div className='row'>
          <div className='col-md-4 col-12'>
            {/* Tabs for Services */}
            <ul className="nav flex-column services-tb nav-pills" id="service-tabs" role="tablist">
              <li className="nav-item">
                <button className="nav-link active" id="handyman-tab" data-bs-toggle="pill" data-bs-target="#handyman" type="button" role="tab" aria-controls="handyman" aria-selected="true">
                  Handyman Services
                </button>
              </li>
              <li className="nav-item">
                <button className="nav-link" id="interior-decorations-tab" data-bs-toggle="pill" data-bs-target="#interior-decorations" type="button" role="tab" aria-controls="interior-decorations" aria-selected="false">
                  Interior Decorations
                </button>
              </li>
              <li className="nav-item">
                <button className="nav-link" id="carpentry-work-tab" data-bs-toggle="pill" data-bs-target="#carpentry-work" type="button" role="tab" aria-controls="carpentry-work" aria-selected="false">
                  Carpentry Work
                </button>
              </li>
              <li className="nav-item">
                <button className="nav-link" id="air-conditioning-tab" data-bs-toggle="pill" data-bs-target="#air-conditioning" type="button" role="tab" aria-controls="air-conditioning" aria-selected="false">
                  Air-Conditioning
                </button>
              </li>
              <li className="nav-item">
                <button className="nav-link" id="electrical-plumbing-tab" data-bs-toggle="pill" data-bs-target="#electrical-plumbing" type="button" role="tab" aria-controls="electrical-plumbing" aria-selected="false">
                  Electrical & Plumbing
                </button>
              </li>
              <li className="nav-item">
                <button className="nav-link" id="painting-tab" data-bs-toggle="pill" data-bs-target="#painting" type="button" role="tab" aria-controls="painting" aria-selected="false">
                  Interior & Exterior Painting
                </button>
              </li>
              <li className="nav-item">
                <button className="nav-link" id="flooring-tile-tab" data-bs-toggle="pill" data-bs-target="#flooring-tile" type="button" role="tab" aria-controls="flooring-tile" aria-selected="false">
                  Flooring & Tile Works
                </button>
              </li>
              <li className="nav-item">
                <button className="nav-link" id="networking-cctv-tab" data-bs-toggle="pill" data-bs-target="#networking-cctv" type="button" role="tab" aria-controls="networking-cctv" aria-selected="false">
                  Networking & CCTV Installation
                </button>
              </li>
            </ul>
          </div>

          <div className='col-md-8 col-12'>
            {/* Tab Content for Each Service */}
            <div className="tab-content" id="service-tabContent">
              <div className="tab-pane fade show active" id="handyman" role="tabpanel" aria-labelledby="handyman-tab">
                <Service1/>
              </div>
              <div className="tab-pane fade" id="interior-decorations" role="tabpanel" aria-labelledby="interior-decorations-tab">
                <Service2/>
              </div>
              <div className="tab-pane fade" id="carpentry-work" role="tabpanel" aria-labelledby="carpentry-work-tab">
                <Service3/>
              </div>
              <div className="tab-pane fade" id="air-conditioning" role="tabpanel" aria-labelledby="air-conditioning-tab">
                <Service4/>
              </div>
              <div className="tab-pane fade" id="electrical-plumbing" role="tabpanel" aria-labelledby="electrical-plumbing-tab">
                <Service5/>
              </div>
              <div className="tab-pane fade" id="painting" role="tabpanel" aria-labelledby="painting-tab">
                <Service6/>
              </div>
              <div className="tab-pane fade" id="flooring-tile" role="tabpanel" aria-labelledby="flooring-tile-tab">
                <Service7/>
              </div>
              <div className="tab-pane fade" id="networking-cctv" role="tabpanel" aria-labelledby="networking-cctv-tab">
                <Service8/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
