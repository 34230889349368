import React, { useLayoutEffect } from 'react';
import GalleryDesign from '../components/GalleryDesign'

export default function Gallery() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <GalleryDesign/>
    </div>
  )
}
